import { graphql } from "gatsby"
import React from "react"
import PageBuilder from "../components/PageBuilder"

import Seo from "../components/utils/Seo"
import Layout from "../components/Layout"
import ImageBuilder from "../components/ImageBuilder"

export const query = graphql`
  query {
    page: allSanityContact {
      nodes {
        title

        meta {
          title
          description
        }

        _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        pageBuilder {
          ... on SanityDoubleCols {
            _type
            _key
            _rawCol2
            _rawCol1
          }

          ... on SanitySingleCol {
            _key
            _type
            _rawCol
          }
        }
        _rawImageLayoutbuilder(resolveReferences: { maxDepth: 10 })
        imageLayoutbuilder {
          ... on SanityGallery {
            images {
              asset {
                gatsbyImageData(width: 600, height: 700)
              }
            }
          }
          ... on SanitySingleImage {
            asset {
              gatsbyImageData(width: 1500, height: 900)
            }
          }
        }
      }
    }
  }
`

const Contact = props => {
  const page = props.data.page.nodes[0]

  return (
    <Layout>
      <Seo
        title={page.meta && page.meta.title ? page.meta.title : ""}
        description={
          page.meta && page.meta.description ? page.meta.description : ""
        }
      />

      <PageBuilder
        pageBuilder={page.pageBuilder}
        _rawPageBuilder={page._rawPageBuilder}
      />
      <ImageBuilder imageLayoutbuilder={page.imageLayoutbuilder} />
    </Layout>
  )
}

export default Contact
