// src/components/pageBuilder.js
import React from "react"
import SingleCol from "./SingleColumn"
import DoubleCols from "./DoubleColumns"

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder } = props

  // Load the right component, based on the _type from Sanity
  const Components = {
    singleCol: SingleCol,
    doubleCols: DoubleCols,
  }

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index],
      })
    } else {
      return null
    }
  })
}

export default PageBuilder
