import React from "react"
import Figure from "./Figure"
import YouTube from "./Youtube"
import BlockContent from "@sanity/block-content-to-react"

export default function singleColumn({ block, raw, index }) {
  const serializer = {
    types: {
      singleImage: Figure,
      videoEmbed: YouTube,
    },
  }

  return (
    <div className="md:grid md:grid-cols-2 md:gap-10 pagebuilder">
      <div>
        <BlockContent
          className="mt-10 blockContent md:pl-10 md:border-l items-center content-center justify-center relative max-w-screen-md mx-auto"
          blocks={raw.col1}
          serializers={serializer}
        />
      </div>
      <div>
        <BlockContent
          className="mt-10 blockContent items-center content-center justify-center relative max-w-screen-md mx-auto"
          blocks={raw.col2}
          serializers={serializer}
        />
      </div>
    </div>
  )
}
