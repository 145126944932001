import React from "react"
import Figure from "./Figure"
import YouTube from "./Youtube"
import BlockContent from "@sanity/block-content-to-react"

export default function singleColumn({ block, raw, index }) {
  const serializer = {
    types: {
      singleImage: Figure,
      videoEmbed: YouTube,
    },
  }

  return (
    <>
      <BlockContent
        className="pagebuilder mt-10 blockContent text-left items-center content-center justify-center relative max-w-screen-md mx-auto"
        blocks={raw.col}
        serializers={serializer}
      />
    </>
  )
}
